*{
    margin: 0;
    padding: 0;
  }
 
  .tabText{
    font-size: 0.8em !important;
  }
  .linkColor{color: #007bff !important;}
  .errorDiv{
    color:#ff0000;
    text-align: center;
    width: 100%;
  }
  .fc .fc-toolbar-title{
    font-size: 18px !important;
    margin: 0;
  }
  .fc .fc-button {
    line-height: 1 !important;
  }
  .fc .fc-button .fc-icon {
    vertical-align: middle;
    font-size: 16px !important;
  }
  .fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
    position: absolute;
    left: 0;
    right: 0;
    top: 16px !important;
  }
  .fc .fc-daygrid-more-link {
    background-color: #0395e8 !important;
  }
  
  .issueDescriptionCard{
    height: 20px;
  }
  .activityBox{
    width: calc(100% - 1px);
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 3px 0 8px rgb(0 0 0 / 33%);
  }
  

  /******************************* Common Styling **********************************/
  h1, h2, h3, h4, h5, h6{
    margin-bottom: 0 !important;
  }
  a{
    color: #000 !important;
    text-decoration: none !important;
  }
  p{
    margin-bottom: 0 !important;
    line-height: 18px !important;
    font-size:14px !important;
  }
  .text-success{
    color: #4fa026;
  }
  .text-blue{
    color: #0093e9 !important;
  }
  .bg-success{
    background-color: #4fa026;
  }
  .bg-warning{
    background-color: #e1711f;
  }
  .bg-danger{
    background-color: #b11b1b;
  }
  .truncate {
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .truncate-1 {
    -webkit-line-clamp: 1;
  }
  .truncate-2 {
    -webkit-line-clamp: 2;
  }
  .truncate-3 {
    -webkit-line-clamp: 3;
  }
  .truncate-4 {
    -webkit-line-clamp: 4;
  }
  .truncate-6 {
    -webkit-line-clamp: 6
  }
  .btn-link:focus{
    box-shadow: none !important;
  }
  .btn .las{
    font-size: 16px;
  }
  .btn-link {
    height: 36px;
    font-size: 14px !important;
    line-height: 26px;
  }
  .btn-link .las{
    font-size: 24px;
  }
  .btn-primary{
    transition: 0.5s !important;
    flex: 1 1 auto !important;
    background-size: 200% auto !important;
    min-width: 110px !important;
    height: 36px;
    font-size: 14px !important;
    line-height: 24px !important;
    border: none !important;
    background-color: transparent !important;
    border-radius: 30px !important;
    color: #ffffff !important;
    background-image: linear-gradient(to right, #0093e9 0%, #0093e9 62%);
  }
  
  .btn-primary:hover, .btn-primary:focus{
    color: #fff !important;
    background-position: right center !important;
  }
  
  .btn-primary.disabled, .btn-primary:disabled{
    border-color: transparent !important;
  }
  .btn-primary:focus,
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle{
    color: #fff !important;
    background-color: transparent;
    background-image: linear-gradient(to right, #0093e9 0%, #0093e9 62%);
    box-shadow: none !important;
  }
  
  .btn-secondary{
    transition: 0.5s !important;
    background-size: 200% auto !important;
    min-width: 110px !important;
    height: 36px;
    font-size: 14px !important;
    line-height: 24px !important;
    border: none !important;
    background-color: transparent !important;
    border-radius: 30px !important;
    color: #ffffff !important;
    background-image: linear-gradient(to right, #0093e9  0%, #0093e9 62%);
  }
  
  .btn-secondary:hover, .btn-secondary:focus{
    color: #fff !important;
    background-position: right center !important;
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled{
    border-color: transparent !important;
  }
  .btn-secondary:focus,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .show>.btn-secondary.dropdown-toggle{
    color: #fff !important;
    background-color: transparent;
    background-image: linear-gradient(to right, #0093e9 0%, #0093e9 62%);
    box-shadow: none !important;
  }
  
  .btn-outline-light{
    font-size: 14px !important;
    border-radius: 20px !important;
    height: 34px !important;
    line-height: 21px !important;
    color: #fff !important;
  }
  .btn-outline-light:hover{
    color: #000 !important;
  }
  .btn-outline-light:focus{
    box-shadow: none !important;
  }
  .btn-outline-light .fa{
    margin-left: 7px;
  }

  
  .chat-input{
    position: fixed;
    bottom: 60px;
    /* width: calc(640px); */
    width:88%;
    padding: 15px;
    background-color: #fff;
  }
  .chat-input .input-holder{
    padding: 10px;
    border-radius: 30px;
    border:1px solid #e9e9e9;
  }
  .chat-input .input-holder .input-group-prepend .btn-link{
    text-decoration: none;
    padding: 0;
  }
  .chat-input .input-holder .input-group-prepend .input-group-text{
    padding: 0 .75rem 0 0;
    border-color: transparent;
    background: linear-gradient(to right, #0093e9 0%, #80d0c7 62%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }
  .chat-input .input-holder .form-control::placeholder{
    line-height: 30px;
  }
  .chat-input .input-holder .form-control{
    resize: none;
    overflow: auto !important;
  }
  .chat-input .input-holder .form-control,
  .chat-input .input-holder .form-control:hover,
  .chat-input .input-holder .form-control:focus{
    border-color: transparent !important;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 33px;
    height: 31px;
  }
  .chat-input .btn-secondary{
    min-width: inherit !important;
    width: 52px;
    height: 52px;
    margin-left: 15px;
  }
  .chat-input .btn-secondary .las{
    font-size: 30px !important;
  }
  .input-holder .btn-link,
  .input-holder .btn-link:hover,
  .input-holder .btn-link:focus{
    padding: 0 .50rem;
    height: 30px;
    color: #666666;
  }
  .chat-message .date{
    display: block;
    text-align: center;
    margin: 10px auto;
    font-weight: bold;
    font-size: 14px;
    color: #000;
    padding: 5px;
    width: auto;
    border-radius: 20px;
  }
  
  .user-chat{
    margin-bottom: 15px;
  }
  .user-chat figure{
    width: 45px;
    height: 45px;
    display: flex;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 0;
  }
  .user-chat.sender figure{
    margin-right: 15px;
  }
  .user-chat.receiver  figure{
    margin-left: 15px;
  }
  .user-chat figure img{
    border-radius: 10px;
  }
  .user-chat .message{
    padding: 8px;
    padding-right: 16px;
    padding-left: 16px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 16px;
  }
  .user-chat .message p{
    font-size: 14px;
    color: #666666;
    margin-bottom: 5px !important;
  }
  .user-chat .message p.date-time{
    text-align: right;
    color: #ccc;
    margin-bottom: 0px !important;
  }
  .user-chat .message .msg-img{
    margin-bottom: 10px;
    border-radius: 10px;
    max-width: 250px;
  }
  .sender-chat-bubble{
   background-color: rgb(22, 50, 62);
   color: white;
  }
  
  .bg-light-pink{
    background-color: #fdebee;
  }
  .bg-light-yellow{
    background-color: #fef9e8;
  }
  .bg-light-yellow-too{
    background-color: #f4f9f3;
  }
  .bg-light-gray{
    background-color: #e9f9fc;
  }
  .user-chat.receiver .message{
    /* background: linear-gradient(to right, #0093e9 0%, #80d0c7 100%) !important; */
    /* bluegrey background */
    background-color: #06f;
  }
  .user-chat.receiver .message p{
    color: #fff;
  }
  

  @media (max-width: 480px){
    .chat-input{
      width: calc(100%);
      padding: 0 33px 6px 10px;
      margin: 0 -15px;
    }
    .chat-input .input-holder {
      padding: 0px;
    }

    .chat-input .btn-secondary {
      width: 40px;
      height: 36px;
      margin-left: 7px;
    }
    .user-chat .message .msg-img{
      max-width: 100%;
    }
 
  }

  .chatPosition{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: aliceblue;
    padding: 10px;
  }
  .profileImg{
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
    float: right;
    margin: 6px 10px 0px 0px;
    background-color: #ccc;
  }
  .userProfiles{
    font-size: 36px;
    padding: 0px 0px 0px 6px;
  }