.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}

.middle {
  width: 100%;
  overflow: hidden !important;
}

.footer {
  background: white;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.avatarCircle {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: #fab301;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tallAndWide {
  font-size: 18px;
  font-weight: bold;
}

.activeButton {
  padding: 20px;
  width: 100%;
  text-align: center;
  color: white;
  background-color: black;
}

.activeButton:hover {
  background-color: #fab301;
  color: #fff;
  cursor: pointer;
}

.inactiveButton {
  padding: 20px;
  width: 100%;
  text-align: center;
}

.inactiveButton:hover {
  background-color: #fab301;
  color: #fff;
  cursor: pointer;
}

/* chat */

.fab-chat-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  background-color: #f1f1f1;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.75);
}

/* fab-chat-button hover with image filter invert */
.fab-chat-button:hover {
  background-color: #a7f78a;
  box-shadow: 0px 0px 50px 0px rgba(255, 255, 255, 0.75);
  filter: invert(100%);
}

.chat-window {
  position: fixed;
  bottom: 8rem;
  right: 2rem;
  min-width: 300px;
  width: 600px;
  max-width: 700px;
  height: 80vh;
  border-radius: 20px;
  background-color: #f1f1f1;
  z-index: 1000;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
}

.group-chat-height-control {
  height: 58vh;
  background-color: rgb(249, 240, 242);
}

.chat-topBar {
  color: black;
  /* display: none; */
}

.hover {
  color: black;
}

.hover:hover {
  cursor: pointer;
  color: #06f;
}

/* send-button is round blue button */
.send-button {
  background-color: rgb(22, 50, 62);
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  color: white;
}

/* send button hover */
.send-button:hover {
  background-color: #06f;
  cursor: pointer;
}

/* send button active */
.send-button:active {
  background-color: rgb(6, 74, 176);
  cursor: pointer;
}

/* Make .chat-window full screen on mobile sizes */
@media only screen and (max-width: 600px) {
  .chat-window {
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 0px;
    background-color: #f1f1f1;
    z-index: 1000;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.25);
  }

  .chat-topBar {
    display: block;
  }

  .fab-chat-button {
    bottom: 1rem;
    right: 1rem;
    z-index: 1000;
    background-color: #f1f1f1;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
  }

  .group-chat-height-control {
    height: 73vh;
    background-color: rgb(249, 240, 242);
  }
}
